import { Column } from '@components/LinearLayout';
import FooterMenu from '@composites/FooterMenu';
import FooterSocial from '../FooterSocial';
const EventFooter = () => {
  return (
    <Column>
      <FooterMenu />
      <FooterSocial />
    </Column>
  );
};

export default EventFooter;
