import { Column, Row } from '@components/LinearLayout';
import {
  dividerAscendant,
  dividerDescendant,
  growDown,
  growUp,
} from '@theme/animations';
import styled, { css } from 'styled-components';

import { animated } from '@react-spring/web';

export const MainMenuItemRoot = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const MainMenuItemWrapper = styled.div<{
  $isSelected: boolean;
  $isMultiMenu: boolean;
  $color: string;
  $isHovered: boolean | null;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  background-color: ${({ $isHovered, $isMultiMenu, theme, $isSelected }) => {
    if (($isHovered && $isMultiMenu) || $isSelected)
      return theme.color.strokeGrey;
    else return 'transparent';
  }};
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    color: ${({ $color }) => $color};
    transition: background-color 300ms ease-in;
  }
`;

export const DividerWrapper = styled.div<{ $isHovered: boolean | null }>`
  position: absolute;
  bottom: 0;
  ${({ $isHovered }) => {
    if ($isHovered === null) return 'display: none';
    if ($isHovered)
      return css`
        animation: ${dividerAscendant} 200ms ease-in forwards;
      `;
    else
      return css`
        animation: ${dividerDescendant} 200ms ease-in forwards;
      `;
  }}
`;

export const MainMenuDropdown = styled(Row)`
  align-items: center;
`;
export const RotateIcon = styled(animated.div)`
  position: relative;
  top: 0.2rem;
`;

export const SubMenuItems = styled(Column)<{ $isHovered: boolean | null }>`
  position: absolute;
  width: inherit;
  align-items: center;
  padding: 2rem 0 4rem 0;
  background-color: ${({ theme }) => theme.color.strokeGrey};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.radius20};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.radius20};
  gap: 1rem;
  ${({ $isHovered }) => {
    if ($isHovered === null) return 'display: none';
    if ($isHovered)
      return css`
        animation: ${growDown} 200ms ease-in forwards;
      `;
    else
      return css`
        animation: ${growUp} 200ms ease-in forwards;
      `;
  }}
`;
