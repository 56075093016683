export const NAV_HEIGHT = '8rem';
export const NAV_SMALL_HEIGHT = '5rem';

export const FOOTER_WIDTH = '125rem';
export const EVENT_SECTION_WIDTH = '154rem';
export const BLOG_ITEM_WIDTH = '50rem';
export const SPEAKER_PAGE_WIDTH = '113rem';
export const EVENT_HOME_WIDTH = '140rem';
export const CONTACT_FORM_WIDTH = '84rem';
export const HEADER_HEIGHT = '6rem';

export const EMAIL = {
  SUPPORT: 'support@we-conect.com',
  LUFTHANSA: 'lufthansa.mobility@dhl.de',
  OPERATIONS: 'eike.nenninger@we-conect.com',
};
export const THEME = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
} as const;

export const THEME_TYPE = ['LIGHT', 'DARK'] as const;

export const MODE = {
  OVERVIEW: 'overview',
  PORTFOLIO: 'portfolio',
  EVENT: 'event',
} as const;

export const MODE_TYPE = [MODE.OVERVIEW, MODE.PORTFOLIO, MODE.EVENT] as const;

export const LANGUAGE = {
  ENGLISH: 'en',
  GERMAN: 'de',
} as const;

export const LANGUAGE_TYPE = [LANGUAGE.ENGLISH, LANGUAGE.GERMAN] as const;

export const LANGUAGE_INTL = {
  en: 'en-GB',
  de: 'de-DE',
} as const;

export const LANGUAGE_INTL_TYPE = ['en-GB', 'de-DE'] as const;

export const SPONSOR_EMAIL = 'partnering@we-conect.com';
export const SPEAKER_EMAIL = 'speaking@we-conect.com';
export const ATTENDEE_EMAIL = 'bookings@we-conect.com';
export const MEDIA_PARTNERSHIP_EMAIL = 'marketing@we-conect.com';
export const GENERAL_INQUIRIES = 'info@we-conect.com';

export const LOCAL_STORAGE_TYPE = {
  LANGUAGE: 'language',
};

export const LANGUAGE_SWITCHER_MODE = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;

export const LANGUAGE_SWITCHER_MODE_TYPE = [
  LANGUAGE_SWITCHER_MODE.LIGHT,
  LANGUAGE_SWITCHER_MODE.DARK,
] as const;

export const SCROLL_DOWN_MODE = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;

export const SCROLL_DOWN_MODE_TYPE = [
  SCROLL_DOWN_MODE.LIGHT,
  SCROLL_DOWN_MODE.DARK,
] as const;

export const PARTNER = {
  COMPETENCE: 'competence',
  BUSINESS: 'business',
  EVENT_LEAD: 'event-lead',
  PRIMARY: 'primary',
  MEDIA: 'media',
} as const;

export const PARTNER_TYPE = [
  PARTNER.COMPETENCE,
  PARTNER.BUSINESS,
  PARTNER.EVENT_LEAD,
  PARTNER.PRIMARY,
  PARTNER.MEDIA,
] as const;
