import EventFooter from '@composites/EventFooter';
import { MODE } from '@utils/constants';
import { useSelector } from 'react-redux';
import { getMode } from '../../store/slices/configSlice';

function Footer() {
  const mode = useSelector(getMode);

  if (mode === MODE.OVERVIEW) return null;
  if (mode === MODE.EVENT) return <EventFooter />;

  return null;
}

export default Footer;
