import { ButtonTitle, HeaderButtonRoot } from './styles';

import { useState } from 'react';

interface HeaderButtonProps {
  title: string;
  onClick: () => void;
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  textHoverColor?: string;
  wrapText?: boolean;
}

const HeaderButton = ({
  title,
  onClick,
  borderColor,
  backgroundColor,
  textColor,
  textHoverColor,
  wrapText,
}: HeaderButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <HeaderButtonRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      onClick={onClick}
    >
      <ButtonTitle
        $textColor={textColor}
        $isHovered={isHovered}
        $textHoverColor={textHoverColor}
        $wrapText={wrapText}
      >
        {title}
      </ButtonTitle>
    </HeaderButtonRoot>
  );
};

export default HeaderButton;
