import { dividerAscendant, dividerDescendant } from '@theme/animations';
import styled, { css } from 'styled-components';

export const SubMenuItemRoot = styled.div<{ $hoverColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    cursor: pointer;
    color: ${({ $hoverColor }) => $hoverColor};
  }
`;

export const DividerWrapper = styled.div<{ $isHovered: boolean | null }>`
  ${({ $isHovered }) => {
    if ($isHovered === null) return 'display: none';
    if ($isHovered)
      return css`
        animation: ${dividerAscendant} 200ms ease-in forwards;
      `;
    else
      return css`
        animation: ${dividerDescendant} 200ms ease-in forwards;
      `;
  }}
`;
