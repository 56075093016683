import { Row } from '@components/LinearLayout';
import { styled } from 'styled-components';

export const SocialFooterRoot = styled(Row)<{ $backgroundColor?: string }>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.color.charcoal};
  justify-content: center;
  padding: 1rem 0 0 0;
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 3rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    justify-content: center;
    margin: 1rem 0 2rem 0;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SocialMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 3rem 0;
  align-items: end;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 1.88rem 0 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 3rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    justify-content: center;
    margin: 0 0 2rem 0;
  }
`;
