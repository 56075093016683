import { RightSideContent, TopHeaderBody } from './styles';

import HeaderButton from '@components/HeaderButton';
import { getEventDetails } from '@store/slices/eventSlice';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const HeaderBottomComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  return (
    <TopHeaderBody
      color={sluggedEvent?.design?.secondaryThemeColor}
      $isLeft={false}
    >
      <RightSideContent $gap="3rem">
        <HeaderButton
          title={t('book-now')}
          borderColor={theme.color.mobilityGrey}
          backgroundColor={theme.color.mobilityGrey}
          textHoverColor={sluggedEvent?.design?.secondaryThemeColor}
          wrapText={false}
          onClick={() => {
            /** */
          }}
        />
      </RightSideContent>
    </TopHeaderBody>
  );
};

export default HeaderBottomComponent;
