import { RuleSet, useTheme } from 'styled-components';

import { DividerRoot } from './styles';

interface DividerProps {
  width: string;
  height: string;
  backgroundColor?: string;
  animation?: () => RuleSet<object>;
  primary?: boolean;
  left?: string;
  right?: string;
}

const Divider = ({
  width,
  height,
  backgroundColor,
  animation,
  primary,
  right,
  left,
}: DividerProps) => {
  const theme = useTheme();
  return (
    <DividerRoot
      $width={width}
      $height={height}
      $backgroundColor={primary ? theme.color.primary : backgroundColor}
      $animation={animation}
      $right={right}
      $left={left}
    />
  );
};

export default Divider;
