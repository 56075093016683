import { LOCAL_STORAGE_TYPE, MODE } from '@utils/constants';
import { useSlug, useViewAnalyzer } from '@utils/hooks';
import { setLanguage, setMode } from '../store/slices/configSlice';

import { EVENTS_PATH } from '@constants/paths';
import { usePublicEventDetailsBySlug } from '@utils/apiHooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setEventDetails } from '../store/slices/eventSlice';

const Routes = ({ children }: { children: React.ReactNode }) => {
  const view = useViewAnalyzer();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const eventSlug = useSlug(EVENTS_PATH) as string;

  const { data: eventDetails, isLoading: isEventLoading } =
    usePublicEventDetailsBySlug({
      eventSlug,
      enabled: view === MODE.EVENT && !!eventSlug,
    });

  useEffect(() => {
    switch (view) {
      case MODE.OVERVIEW:
        dispatch(setMode(MODE.OVERVIEW));
        break;
      case MODE.PORTFOLIO:
        dispatch(setMode(MODE.PORTFOLIO));
        break;
      case MODE.EVENT:
        dispatch(setMode(MODE.EVENT));
        if (eventDetails != null && !isEventLoading) {
          const language = eventDetails?.[0]?.language;
          dispatch(setEventDetails(eventDetails));
          dispatch(setLanguage(language));
          window.localStorage.setItem(LOCAL_STORAGE_TYPE.LANGUAGE, language);
          void i18n.changeLanguage(language);
        }
        break;
      default:
        dispatch(setMode(MODE.OVERVIEW));
        break;
    }
  }, [dispatch, eventDetails, i18n, isEventLoading, view]);

  return <>{children}</>;
};

export default Routes;
