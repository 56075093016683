import 'dayjs/locale/de';
import 'dayjs/locale/en';
import './i18n';
import './theme/fonts.css';

import { getLanguage, setLanguage } from '@store/slices/configSlice';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LANGUAGE, LANGUAGE_TYPE, LOCAL_STORAGE_TYPE } from '@utils/constants';
import ErrorBoundary, { FallBackUI } from '@utils/ErrorBoundary';
import { useCallback, useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import Providers from '@context/Providers';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalStyle from '@theme/GlobalStyle';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import ReduxProvider from './store/ReduxProvider';

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const valueLanguage = window.localStorage.getItem(
    LOCAL_STORAGE_TYPE.LANGUAGE
  );

  const notValidLanguage = useMemo(
    () =>
      valueLanguage !== LANGUAGE.ENGLISH && valueLanguage !== LANGUAGE.GERMAN,
    [valueLanguage]
  );

  const updateLanguage = useCallback(
    (language: (typeof LANGUAGE_TYPE)[number]) => {
      void i18n.changeLanguage(language);
      dispatch(setLanguage(language));
      dayjs.locale(language);
    },
    [dispatch, i18n]
  );
  useEffect(() => {
    if (!valueLanguage || notValidLanguage) {
      updateLanguage(LANGUAGE.ENGLISH);
      window.localStorage.setItem(
        LOCAL_STORAGE_TYPE.LANGUAGE,
        LANGUAGE.ENGLISH
      );
    } else if (
      valueLanguage === LANGUAGE.GERMAN ||
      valueLanguage === LANGUAGE.ENGLISH
    ) {
      updateLanguage(valueLanguage);
    }
  }, [
    dispatch,
    i18n,
    language,
    notValidLanguage,
    updateLanguage,
    valueLanguage,
  ]);

  return (
    <ErrorBoundary fallBackUIComponent={FallBackUI()}>
      <GlobalStyle />
      <Routes />
    </ErrorBoundary>
  );
}

export default function WithProviders() {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <HelmetProvider>
        <ReduxProvider>
          <QueryClientProvider client={queryClient}>
            <Providers>
              <App />
            </Providers>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ReduxProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}
