import { RuleSet, styled } from 'styled-components';

export const DividerRoot = styled.div<{
  $width: string;
  $height: string;
  $backgroundColor?: string;
  $animation?: () => RuleSet<object>;
  $right?: string;
  $left?: string;
}>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  animation: ${({ $animation }) => $animation};
  position: relative;
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
`;
