import {
  Agenda,
  Community,
  Contact,
  EventHome,
  EventInfo,
  EventPage,
  HelmetPage,
  NotFound,
  Partners,
  PortfolioDetails,
  PortfolioHome,
  ROUTES,
  Speakers,
} from '@constants/routes';
import { Route, Routes } from 'react-router-dom';

import LoadingFallBack from './layouts/components/LoadingFallback';
import { Suspense } from 'react';
import { getUrl } from '@utils/fn';
import { useScrollToTop } from '@utils/hooks';
import { useTranslation } from 'react-i18next';

const RoutesRoot = () => {
  const { t } = useTranslation();
  useScrollToTop();

  return (
    <Suspense fallback={<LoadingFallBack />}>
      <Routes>
        <Route path="/" element={<HelmetPage />}>
          <Route index element={<PortfolioHome />} />
        </Route>

        <Route
          path={`${t(ROUTES.PORTFOLIOS)}/:portfolioSlug`}
          element={<HelmetPage />}
        >
          <Route index element={<PortfolioDetails />} />
        </Route>

        <Route path={`${t(ROUTES.EVENTS)}/:eventSlug`} element={<HelmetPage />}>
          <Route index element={<EventHome />} />
          <Route path={getUrl(t('agenda-url'))} element={<Agenda />} />
          <Route path={getUrl(t('speakers-url'))} element={<Speakers />} />
          <Route path={getUrl(t('partners-url'))} element={<Partners />} />
          <Route path={getUrl(t('event-info-url'))} element={<EventInfo />} />
          <Route path={getUrl(t('community-url'))} element={<Community />} />
          <Route path={getUrl(t('contact-url'))} element={<Contact />} />
          <Route path=":pageSlug" element={<EventPage />} />
        </Route>

        <Route path={`${t(ROUTES.BLOGS)}/:blogSlug`} element={<HelmetPage />}>
          <Route index element={<PortfolioHome />} />
        </Route>

        <Route
          path={`${t(ROUTES.WEBINARS)}/:webinarSlug`}
          element={<HelmetPage />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesRoot;
