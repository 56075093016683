export const common = {
  fontSize: {
    size90: '9rem',
    size55: '5.5rem',
    size50: '5rem',
    size45: '4.5rem',
    size40: '4rem',
    size38: '3.8rem',
    size35: '3.5rem',
    size32: '3.2rem',
    size30: '3rem',
    size28: '2.8rem',
    size27: '2.7rem',
    size26: '2.6rem',
    size25: '2.5rem',
    size24: '2.4rem',
    size23: '2.3rem',
    size22: '2.2rem',
    size20: '2rem',
    size18: '1.8rem',
    size17: '1.7rem',
    size16: '1.6rem',
    size15: '1.5rem',
    size14: '1.4rem',
    size125: '1.25rem',
    size12: '1.2rem',
    size11: '1.1rem',
    size10: '1rem',
    size9: '0.9rem',
    size8: '0.8rem',
    size7: '0.7rem',
    size5: '0.5rem',
    size4: '0.4rem',
    size3: '0.3rem',
    size2: '0.2rem',
    size1: '0.1rem',
    size05: '0.05rem',
  },
  borderRadius: {
    radius100: '10rem',
    radius60: '6rem',
    radius50: '5rem',
    radius30: '3rem',
    radius25: '2.5rem',
    radius20: '2rem',
    radius10: '1rem',
    radius5: '0.5rem',
    radius50p: '50%',
  },
  font: {
    header: '"Lato", sans-serif',
    body: '"Lato", Arial',
    inter: '"Lato", sans-serif',
  },
  fontWeight: {
    weight200: '200',
    weight400: '400',
    weight500: '500',
    weight600: '600',
    weight700: '700',
    weight900: '900',
  },
  breakpoint: {
    width1280: '1280px',
    width1080: '1080px',
    width768: '768px',
    width480: '480px',
  },
  lineHeight: {
    height165: '165%',
    height150: '150%',
    height125: '125%',
    height140: '140%',
    height135: '135%',
    height130: '130%',
    height115: '115%',
    height100: '100%',
    height70: '7rem',
    height63: '6.32rem',
    height46: '4.6rem',
    height39: '3.92rem',
    height35: '3.5rem',
    height33: '3.3rem',
    height30: '3rem',
  },
  letterSpacing: {
    negative1: '-1px',
    zero: '0',
    positive1: '1px',
  },
  spacing: {
    size75: '7.5rem',
    size70: '7rem',
    size65: '6.5rem',
    size60: '6rem',
    size55: '5.5rem',
    size54: '5.4rem',
    size50: '5rem',
    size45: '4.5rem',
    size40: '4rem',
    size38: '3.8rem',
    size35: '3.5rem',
    size32: '3.2rem',
    size30: '3rem',
    size26: '2.6rem',
    size25: '2.5rem',
    size24: '2.4rem',
    size23: '2.3rem',
    size22: '2.2rem',
    size20: '2rem',
    size17: '1.7rem',
    size16: '1.6rem',
    size15: '1.5rem',
    size14: '1.4rem',
    size125: '1.25rem',
    size12: '1.2rem',
    size11c5: '1.15rem',
    size11: '1.1rem',
    size10: '1rem',
    size9: '0.9rem',
    size8: '0.8rem',
    size7: '0.7rem',
    size6c5: '0.65rem',
    size6: '0.6rem',
    size5: '0.5rem',
    size4: '0.4rem',
    size3: '0.3rem',
    size2: '0.2rem',
    size1: '0.1rem',
    size0c5: '0.05rem',
  },
};
