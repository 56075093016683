import { DividerWrapper, SubMenuItemRoot } from './styles';

import Divider from '@components/Divider';
import { getEventDetails } from '@store/slices/eventSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface SubMenuItemProps {
  title?: string | null;

  textColor?: string;
  onClick?: () => void;
}

const SubMenuItem = ({ title, onClick }: SubMenuItemProps) => {
  const [isHovered, setIsHovered] = useState<boolean | null>(null);
  const sluggedEvent = useSelector(getEventDetails)?.[0];

  return (
    <SubMenuItemRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        onClick?.();
      }}
      $hoverColor={sluggedEvent?.design.themeColor}
    >
      {title}
      <DividerWrapper $isHovered={isHovered}>
        <Divider
          width="5rem"
          height="0.5rem"
          backgroundColor={sluggedEvent?.design.themeColor}
        />
      </DividerWrapper>
    </SubMenuItemRoot>
  );
};

export default SubMenuItem;
