export const EVENTS_PATH = '/events/';
export const PORTFOLIOS_PATH = '/portfolios/';
export const BLOGS_PATH = '/blogs/';
export const adminEventsRouteFormat = (slug: string, lief: string) =>
  slug?.endsWith('/') || lief?.startsWith('/')
    ? `${EVENTS_PATH}${slug}${lief}`
    : `${EVENTS_PATH}${slug}/${lief}`;

export const adminEventPageRouteFormat = (
  eventSlug: string,
  pageSlug: string
) => `${EVENTS_PATH}${eventSlug}/${pageSlug}`;

export const adminPortfoliosRouteFormat = (slug: string, lief: string) =>
  slug?.endsWith('/') || lief?.startsWith('/')
    ? `${PORTFOLIOS_PATH}${slug}${lief}`
    : `${PORTFOLIOS_PATH}${slug}/${lief}`;
export const adminBlogsRouteFormat = (slug: string, lief: string) =>
  slug?.endsWith('/') || lief?.startsWith('/')
    ? `${BLOGS_PATH}${slug}${lief}`
    : `${BLOGS_PATH}${slug}/${lief}`;
