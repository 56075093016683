import { CenterItem } from '@components/LinearLayout';
import { PSmallBold } from '../Typography';
import { styled } from 'styled-components';

export const HeaderButtonRoot = styled(CenterItem)<{
  $backgroundColor?: string;
  $borderColor?: string;
}>`
  border: 0.05rem solid
    ${({ $borderColor, theme }) => $borderColor ?? theme.color.black};
  padding: 0.5rem 3rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.radius10};
  &:hover {
    background-color: ${({ theme }) => theme.color.white};
    cursor: pointer;
  }
  transition: background-color 600ms cubic-bezier(0.4, 0.47, 0.13, 0.88);
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem 3rem;
  }
`;

export const ButtonTitle = styled(PSmallBold)<{
  $isHovered?: boolean;
  $textColor?: string;
  $textHoverColor?: string;
  $wrapText?: boolean;
}>`
  text-align: center;
  white-space: ${({ $wrapText }) => ($wrapText ? 'normal' : 'nowrap')};
  color: ${props =>
    props.$isHovered
      ? props.$textHoverColor
      : props?.$textColor
        ? props.$textColor
        : props.theme.color.white};
`;
