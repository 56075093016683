import Footer from '@components/Footer';
import { Column } from '@components/LinearLayout';
import { GlobalNav } from '@composites/GlobalNav';
import { THEME } from '@constants/theme';
import Routes from '@routes/Routes';
import { ReactNode } from 'react';
import styled from 'styled-components';
import ThemeProvider from './ThemeProvider';

export const ContentLayout = styled(Column)`
  height: 100%;
  justify-content: space-between;
  background-color: ${props => props.theme.color.transparent};
`;

export const GlobalLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`;

function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={THEME.LIGHT}>
      <Routes>
        <GlobalLayout>
          <GlobalNav />
          <ContentLayout>
            <div>{children}</div>
            <Footer />
          </ContentLayout>
        </GlobalLayout>
      </Routes>
    </ThemeProvider>
  );
}
export default Providers;
