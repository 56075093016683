import { CenterItem, Row } from '@components/LinearLayout';

import styled from 'styled-components';

export const EventMenuRoot = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey2};
  width: 100%;
  display: flex;
  -webkit-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  -moz-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
`;

export const EventMenuBody = styled(Row)`
  width: min(100%, 192rem);
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    padding: 0 3rem;
  }
`;

export const LeftSideContent = styled(Row)<{ $padding: string }>`
  padding: ${({ $padding }) => $padding};
`;
export const RightSideContent = styled(Row)`
  height: 100%;
`;

export const LogoContainer = styled.div<{ $size: number }>`
  display: flex;
  align-items: start;
  width: ${({ $size }) => $size * 4.4}rem;
  height: ${({ $size }) => $size}rem;
  cursor: pointer;
  padding: 0 0 0 1rem;
`;

export const LogoImage = styled.img<{ $height?: string }>`
  height: ${({ $height }) => $height};
  object-fit: contain;
`;

export const ProfileWrapper = styled(CenterItem)`
  padding: 0 1rem;
`;
