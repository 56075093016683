import { LANGUAGE } from '@utils/constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import en from './locales/en.json';

const localStorageLangValue = localStorage.getItem('language');

void i18n.use(initReactI18next).init({
  returnNull: false,
  resources: {
    en,
    de,
  },
  lng: localStorageLangValue ?? LANGUAGE.ENGLISH,
  fallbackLng: LANGUAGE.ENGLISH,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
