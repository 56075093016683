import 'react-loading-skeleton/dist/skeleton.css';

import {
  GENERAL_INQUIRIES,
  MEDIA_PARTNERSHIP_EMAIL,
  SPEAKER_EMAIL,
  SPONSOR_EMAIL,
} from '@utils/constants';
import { Trans, useTranslation } from 'react-i18next';
import {
  CompanyLogo,
  ContactUs,
  DesktopOnlyColumn,
  DetailTitle,
  EventDetailsDescription,
  EventDetailsSection,
  FooterRoot,
  FooterWrapper,
  LinkElement,
  LinkElementRoot,
  MenuFooterRoot,
  TextLink,
} from './styles';

import weLogo from '@assets/images/weCONECT_col_RGB.svg';
import Button from '@components/Button';
import { Column } from '@components/LinearLayout';
import { adminPortfoliosRouteFormat } from '@constants/paths';
import { getLanguage } from '@store/slices/configSlice';
import { getEventDetails } from '@store/slices/eventSlice';
import { usePublicCoLocatedEventsByEventId } from '@utils/apiHooks';
import { getImageSrc } from '@utils/fn';
import { useTabletWidth } from '@utils/hooks';
import { eventTimeFormat } from '@utils/time';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

export function FooterMenu() {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const theme = useTheme();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const isTablet = useTabletWidth();

  const { data, isLoading } = usePublicCoLocatedEventsByEventId({
    eventId: sluggedEvent?.id,
    enabled: sluggedEvent?.id?.length > 0 && sluggedEvent?.colocated,
  });

  const portfolioName = sluggedEvent?.portfolio?.name;

  const location = useMemo(
    () =>
      [
        sluggedEvent?.location?.name,
        sluggedEvent?.location?.marker.street,
        [
          sluggedEvent?.location?.marker?.postalCode,
          sluggedEvent?.location?.marker?.city,
        ]
          ?.filter(e => e)
          ?.join(' '),
      ].filter(e => e) ?? [],
    [
      sluggedEvent?.location?.marker?.city,
      sluggedEvent?.location?.marker?.postalCode,
      sluggedEvent?.location?.marker.street,
      sluggedEvent?.location?.name,
    ]
  );

  const eventElements = useMemo(
    () => [
      { title: t('sponsoring'), link: `mailto:${SPONSOR_EMAIL}` },
      { title: t('to-speaker'), link: `mailto:${SPEAKER_EMAIL}` },
      {
        title: t('media-partnership'),
        link: `mailto:${MEDIA_PARTNERSHIP_EMAIL}`,
      },
      { title: t('general-inquiries'), link: `mailto:${GENERAL_INQUIRIES}` },
    ],
    [t]
  );

  return (
    <MenuFooterRoot $backgroundColor={theme.color.lightGrey}>
      <FooterRoot>
        <FooterWrapper>
          <EventDetailsSection>
            <CompanyLogo
              src={
                sluggedEvent?.design?.eventLogoPosSvg?.length
                  ? getImageSrc(sluggedEvent?.design?.eventLogoPosSvg)
                  : weLogo
              }
              alt={
                sluggedEvent?.name?.length
                  ? sluggedEvent?.name
                  : 'WeConect Logo'
              }
              loading="lazy"
            />
            <EventDetailsDescription>
              {sluggedEvent?.name}
              <Trans
                i18nKey="event-details-description"
                portfolioName={sluggedEvent?.portfolio?.name}
              >
                is part of the {{ portfolioName }}. More information can be
                found
                <TextLink
                  href={adminPortfoliosRouteFormat(
                    sluggedEvent?.portfolio?.slug,
                    ''
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  $color={sluggedEvent?.design?.themeColor}
                >
                  here
                </TextLink>
                .
              </Trans>
            </EventDetailsDescription>
          </EventDetailsSection>
          <EventDetailsSection>
            <Column>
              <DetailTitle>{t('date')}</DetailTitle>
              {isLoading ? (
                <Skeleton
                  count={1}
                  width="20rem"
                  baseColor="#dedede"
                  enableAnimation
                />
              ) : (
                <EventDetailsDescription>
                  {eventTimeFormat(
                    sluggedEvent?.start_date,
                    sluggedEvent?.end_date,
                    language
                  )}
                </EventDetailsDescription>
              )}
            </Column>
            {location?.filter(e => e)?.length > 0 && (
              <Column>
                <DetailTitle>{t('location')}</DetailTitle>

                {isLoading ? (
                  <Skeleton
                    count={3}
                    width="15rem"
                    enableAnimation
                    baseColor="#dedede"
                    style={{ marginTop: '0.8rem' }}
                  />
                ) : (
                  <Column $gap="0.3rem">
                    {location?.map(item => (
                      <EventDetailsDescription key={item}>
                        {item}
                      </EventDetailsDescription>
                    ))}
                  </Column>
                )}
              </Column>
            )}
          </EventDetailsSection>
          <EventDetailsSection>
            <ContactUs href="mailto:contact@we-conect.com">
              <DetailTitle $color={sluggedEvent?.design?.themeColor}>
                {t('contact-us')}
              </DetailTitle>
            </ContactUs>
            <DesktopOnlyColumn>
              <DetailTitle>{t('email')}</DetailTitle>
              <Column $gap="0.5rem">
                {eventElements?.map(item => (
                  <LinkElementRoot key={item.title} to={item.link}>
                    <LinkElement $color={sluggedEvent?.design?.themeColor}>
                      <span>&gt;</span> <span>{item.title}</span>
                    </LinkElement>
                  </LinkElementRoot>
                ))}
              </Column>
            </DesktopOnlyColumn>
          </EventDetailsSection>
          {sluggedEvent?.colocated ? (
            <EventDetailsSection>
              <EventDetailsDescription>
                {t('this-event-is-co-located-with')}
              </EventDetailsDescription>
              {isLoading ? (
                <Column $gap="1.5rem">
                  <Skeleton
                    count={1}
                    enableAnimation
                    width="15rem"
                    height="10rem"
                    baseColor="#dedede"
                  />
                  <Skeleton
                    count={1}
                    enableAnimation
                    width="15rem"
                    height="10rem"
                    baseColor="#dedede"
                  />
                </Column>
              ) : (
                <Column $gap="2rem">
                  {data
                    ?.filter(e => e?.id !== sluggedEvent?.id)
                    ?.map(event => (
                      <CompanyLogo
                        key={event?.id}
                        src={getImageSrc(event?.images.logoTransparent)}
                      />
                    ))}
                </Column>
              )}
            </EventDetailsSection>
          ) : (
            <EventDetailsSection>
              <EventDetailsDescription>
                {t('add-event-to-calendar-text')}
              </EventDetailsDescription>

              <Button
                title={t('save-the-date')}
                backgroundColor={sluggedEvent?.design?.themeColor}
                hoverBackgroundColor={theme.color.mobilityGrey}
                textColor={theme.color.white}
                borderRadius={theme.borderRadius.radius10}
                padding={isTablet ? '0.9rem 4.5rem' : '0.5rem 2.5rem'}
                width={isTablet ? 'auto' : '100%'}
                fontSize={theme.fontSize.size20}
                onClick={() => {
                  /** */
                }}
              />
            </EventDetailsSection>
          )}
        </FooterWrapper>
      </FooterRoot>
    </MenuFooterRoot>
  );
}

export default FooterMenu;
