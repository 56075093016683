import { createSelector, createSlice } from '@reduxjs/toolkit';

import { Portfolio } from '@hubs101/booking-api-skd-client/lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface IPortfolioState {
  portfolioDetails: Portfolio;
  eventSlugs: string[];
}

const initialState: IPortfolioState = {
  portfolioDetails: {} as unknown as Portfolio,
  eventSlugs: [] as string[],
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioDetails: (state, action: PayloadAction<Portfolio>) => {
      state.portfolioDetails = action.payload;
    },
    setEventSlugs: (state, action: PayloadAction<string[]>) => {
      state.eventSlugs = action.payload;
    },
  },
});
export const getPortfolioDetails = createSelector(
  (state: RootState) => state.portfolio,
  portfolio => portfolio.portfolioDetails
);

export const getEventSlugs = createSelector(
  (state: RootState) => state.portfolio,
  portfolio => portfolio.eventSlugs
);

export const { setPortfolioDetails } = portfolioSlice.actions;

export const portfolioReducer = portfolioSlice.reducer;
