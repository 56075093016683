import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EventMenuBody,
  EventMenuRoot,
  LeftSideContent,
  LogoContainer,
  LogoImage,
  ProfileWrapper,
  RightSideContent,
} from './styles';

import weLogo from '@assets/images/weCONECT_col_RGB.svg';
import Icon from '@components/Icon';
import MainMenuItemDesktop from '@composites/MainMenuItemDesktop';
import { MenuItem as MenuItemType } from '@hubs101/booking-api-skd-client/lib/types';
import { getEventDetails } from '@store/slices/eventSlice';
import { getImageSrc } from '@utils/fn';
import { useDesktopWidth } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

export const mappingLinks = {
  home: '',
  agenda: 'agenda-url',
  speaker: 'speakers-url',
  partner: 'partners-url',
  'event-info': 'event-info-url',
  'who-&-why': '/who-why',
  'content-hub': 'content-hub-url',
};

const EventMenu = ({
  TopHeaderComponent,
}: {
  TopHeaderComponent?: JSX.Element | null;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const [scrollHeight, setScrollHeight] = useState(0);
  const isDesktop = useDesktopWidth();

  const sortedMenuItems = useMemo(
    () =>
      sluggedEvent?.website_menu
        ?.find(e => e.type === 'mainmenu')
        ?.items?.toSorted(
          (a: MenuItemType, b: MenuItemType) => a.order - b.order
        ),
    [sluggedEvent?.website_menu]
  );

  const link = useCallback(
    (item: MenuItemType) => {
      switch (item.type) {
        case 'internal':
          return `/${item.url}`;
        case 'external':
          return item.url;
        default:
          return t(mappingLinks[item.type as keyof typeof mappingLinks]);
      }
    },
    [t]
  );

  const mainMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => !e.level)
        ?.slice(0, 6)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
          };
        }),
    [link, sortedMenuItems]
  );

  const secondaryMenuItems = useMemo(
    () =>
      sortedMenuItems
        ?.filter(e => e.level)
        ?.map(item => {
          return {
            id: item.label,
            title: item.label,
            subTitle: item.label,
            link: link(item),
            order: item.order,
            index: mainMenuItems?.findLastIndex(
              element => element.order < item.order
            ),
          };
        }) ?? [],
    [link, mainMenuItems, sortedMenuItems]
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      setScrollHeight(scrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const padding = useMemo(() => {
    if (scrollHeight <= 100) return `${2 - 0.016 * scrollHeight}rem 0`;
    else return '0.4rem 0';
  }, [scrollHeight]);

  const size = useMemo(() => {
    if (scrollHeight <= 100) return 5.4 - 0.014 * scrollHeight;
    else return 4;
  }, [scrollHeight]);

  if (isDesktop) return null;

  return (
    <>
      {TopHeaderComponent}
      <EventMenuRoot>
        <EventMenuBody>
          <LeftSideContent $padding={padding}>
            <LogoContainer $size={size}>
              <LogoImage
                src={
                  sluggedEvent?.design?.eventLogoPosSvg?.length > 0
                    ? getImageSrc(sluggedEvent?.design?.eventLogoPosSvg)
                    : weLogo
                }
                alt={
                  sluggedEvent?.name?.length
                    ? sluggedEvent?.name
                    : 'WeConect Logo'
                }
                $height={`${size}rem`}
              />
            </LogoContainer>
          </LeftSideContent>
          <RightSideContent>
            {mainMenuItems?.map((item, index) => (
              <MainMenuItemDesktop
                key={item.id}
                title={item.title}
                link={item.link}
                subMenuItems={secondaryMenuItems?.filter(
                  item => item.index === index
                )}
              />
            ))}

            <ProfileWrapper>
              <Icon
                color={theme.color.shadeGrey2}
                indentLess
                name="profile"
                $hoverEffect
                size={28}
                $clickable
              />
            </ProfileWrapper>
          </RightSideContent>
        </EventMenuBody>
      </EventMenuRoot>
    </>
  );
};

export default EventMenu;
