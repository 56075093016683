import { LANGUAGE, LANGUAGE_TYPE, MODE, MODE_TYPE } from '@utils/constants';
import {
  cookieGdprName,
  cookieInboundName,
  getCookie,
} from '@utils/Inbound/inboundHelper';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface gdprConfigProps {
  essential: boolean;
  marketing: { googleAnalytics: boolean; mailTracking: boolean };
  externalMedia: { youtube: boolean; vimeo: boolean };
}
export interface IConfigState {
  isMenuOpen: boolean;
  mode: (typeof MODE_TYPE)[number];
  language: (typeof LANGUAGE_TYPE)[number];
  cookie: undefined | string;
  gdprCookie: undefined | string;
  inboundConfig: { email: string; secret: string; visitorId: string };
  gdprConfig: gdprConfigProps;
}

const initialState: IConfigState = {
  isMenuOpen: false,
  mode: MODE.OVERVIEW,
  language:
    localStorage.getItem('language') === LANGUAGE.GERMAN
      ? LANGUAGE.GERMAN
      : LANGUAGE.ENGLISH,
  cookie: getCookie(cookieInboundName) ?? undefined,
  gdprCookie: getCookie(cookieGdprName) ?? undefined,
  inboundConfig: { email: '', secret: '', visitorId: '' },
  gdprConfig: {
    essential: true,
    marketing: { googleAnalytics: false, mailTracking: false },
    externalMedia: { youtube: false, vimeo: false },
  },
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setIsMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload;
    },
    setMode: (state, action: PayloadAction<(typeof MODE_TYPE)[number]>) => {
      state.mode = action.payload;
    },
    setLanguage: (
      state,
      action: PayloadAction<(typeof LANGUAGE_TYPE)[number]>
    ) => {
      state.language = action.payload;
    },
    setInboundCookie: (state, action: PayloadAction<undefined | string>) => {
      state.cookie = action.payload;
    },
    setGdprCookie: (state, action: PayloadAction<undefined | string>) => {
      state.gdprCookie = action.payload;
    },
  },
});
export const getMode = createSelector(
  (state: RootState) => state.config,
  config => config.mode
);
export const getInboundCookie = createSelector(
  (state: RootState) => state.config,
  config => config.cookie
);

export const getGdprCookie = createSelector(
  (state: RootState) => state.config,
  config => config.gdprCookie
);
export const getMenuState = createSelector(
  (state: RootState) => state.config,
  config => config.isMenuOpen
);
export const getLanguage = createSelector(
  (state: RootState) => state.config,
  config => config.language
);

export const {
  setIsMenuOpen,
  setMode,
  setInboundCookie,
  setLanguage,
  setGdprCookie,
} = configSlice.actions;

export const configReducer = configSlice.reducer;
