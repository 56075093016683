import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface IAccountState {
  portfolioSlugs: string[];
}

const initialState: IAccountState = {
  portfolioSlugs: [] as string[],
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
});
export const getPortfolioSlugs = createSelector(
  (state: RootState) => state.account,
  account => account.portfolioSlugs
);

export const accountReducer = accountSlice.reducer;
