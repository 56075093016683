import styled from 'styled-components';

export const HamburgerMenuRoot = styled.div<{ backgroundColor?: string }>`
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.color.transparent};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
`;

const Line = styled.path<{ strokeColor?: string }>`
  fill: none;
  stroke: ${props =>
    props.strokeColor ? props.strokeColor : props.theme.color.black};
  stroke-width: 6;
  transition:
    stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Line1 = styled(Line)<{ $isOpen?: boolean }>`
  stroke-dasharray: ${props => (props.$isOpen ? '90 207' : '60 207')};
  stroke-dashoffset: ${props => props.$isOpen && '-134'};
  stroke-width: 6;
`;

export const Line2 = styled(Line)<{ $isOpen?: boolean }>`
  stroke-dasharray: ${props => (props.$isOpen ? '1 60' : '60 60')};
  stroke-dashoffset: ${props => props.$isOpen && '-30'};
  stroke-width: 6;
`;
export const Line3 = styled(Line)<{ $isOpen?: boolean }>`
  stroke-dasharray: ${props => (props.$isOpen ? '90 207' : '60 207')};
  stroke-dashoffset: ${props => props.$isOpen && '-134'};
  stroke-width: 6;
`;
