import { Column, Row } from '@components/LinearLayout';
import { fadeIn, fadeOut } from '@theme/animations';
import styled, { css } from 'styled-components';

export const menuAnimationDuration = 400;

export const EventMenuRoot = styled(Column)`
  background-color: ${({ theme }) => theme.color.lightGrey2};
  display: flex;
  -webkit-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  -moz-box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
  box-shadow: inset 0 -0.5rem 0 ${({ theme }) => theme.color.strokeGrey};
`;

export const EventMenu = styled(Row)`
  padding: 1.7rem 10rem;
  align-items: center;
  justify-content: space-between;
`;

export const EventMenuBody = styled(Row)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

export const TopContent = styled(Row)`
  padding: 1rem 2rem;
  justify-content: space-between;
`;
export const BottomContent = styled(Column)<{ $open: boolean }>`
  overflow: hidden;
  max-height: 0;

  -webkit-transition: max-height ${menuAnimationDuration}ms;
  -moz-transition: max-height ${menuAnimationDuration}ms;
  -ms-transition: max-height ${menuAnimationDuration}ms;
  -o-transition: max-height ${menuAnimationDuration}ms;
  transition: max-height ${menuAnimationDuration}ms;

  ${({ $open }) => {
    if ($open)
      return css`
        animation: ${fadeIn} ${menuAnimationDuration}ms ease-in forwards;
      `;
    else
      return css`
        animation: ${fadeOut} ${menuAnimationDuration}ms ease-in forwards;
      `;
  }}
  ${({ $open }) => {
    if ($open)
      return css`
        max-height: 100vh;
      `;
    else
      return css`
        max-height: 0;
      `;
  }}
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BottomDetailsContent = styled(Column)<{
  $open: boolean;
  $backgroundColor?: string;
}>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.color.lightGrey2};
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 100%;
  -webkit-transition: left ${menuAnimationDuration}ms;
  -moz-transition: left ${menuAnimationDuration}ms;
  -ms-transition: left ${menuAnimationDuration}ms;
  -o-transition: left ${menuAnimationDuration}ms;
  transition: left ${menuAnimationDuration}ms;

  ${({ $open }) => {
    if ($open)
      return css`
        left: 0;
      `;
    else
      return css`
        left: 100%;
      `;
  }}
`;

export const LogoContainer = styled.div<{ $size: number }>`
  display: flex;
  align-items: start;
  height: ${({ $size }) => $size}rem;
  cursor: pointer;
`;

export const LogoImage = styled.img<{ $height?: string }>`
  height: ${({ $height }) => $height};
  object-fit: contain;
`;

export const BackIcon = styled.div`
  margin: 1.5rem 0 0 1.5rem;
  width: 4rem;
  height: 4rem;
`;

export const SubMenuItems = styled(Column)``;
