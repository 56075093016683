import { LoadCircle, LoadCircleInner, LoadInner, SpinnerRoot } from './styles';

import { CenterItem } from '@components/LinearLayout';

const Spinner = () => {
  return (
    <CenterItem>
      <SpinnerRoot>
        <LoadInner>
          <LoadCircle>
            <LoadCircleInner />
          </LoadCircle>
          <LoadCircle>
            <LoadCircleInner />
          </LoadCircle>
          <LoadCircle>
            <LoadCircleInner />
          </LoadCircle>
          <LoadCircle>
            <LoadCircleInner />
          </LoadCircle>
          <LoadCircle>
            <LoadCircleInner />
          </LoadCircle>
        </LoadInner>
      </SpinnerRoot>
    </CenterItem>
  );
};

export default Spinner;
