import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import { PublicEventDetailsResponse } from '@hubs101/booking-api-skd-client/lib/types';
import { RootState } from '..';

export interface IEventState {
  eventDetails: PublicEventDetailsResponse[];
  agendaFilters: Record<string, string[]>;
  communityFilters: Record<string, string[]>;
}

const initialState: IEventState = {
  eventDetails: [] as PublicEventDetailsResponse[],
  agendaFilters: {} as Record<string, string[]>,
  communityFilters: {} as Record<string, string[]>,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventDetails: (
      state,
      action: PayloadAction<PublicEventDetailsResponse[]>
    ) => {
      state.eventDetails = action.payload;
    },
    setAgendaFilters: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.agendaFilters = action.payload;
    },
    setCommunityFilters: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.communityFilters = action.payload;
    },
  },
});

export const getEventDetails = createSelector(
  (state: RootState) => state.event,
  event => event.eventDetails
);
export const getAgendaFilters = createSelector(
  (state: RootState) => state.event,
  event => event.agendaFilters
);

export const getCommunityFilters = createSelector(
  (state: RootState) => state.event,
  event => event.communityFilters
);

export const { setEventDetails, setAgendaFilters, setCommunityFilters } =
  eventSlice.actions;

export const eventReducer = eventSlice.reducer;
