import { CenterItem } from '@components/LinearLayout';
import { HEADER_HEIGHT } from '@utils/constants';
import styled from 'styled-components';

export const Root = styled(CenterItem)`
  height: calc(100vh - 3 * ${HEADER_HEIGHT});
  padding: 1rem;
  text-align: center;
`;
export const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;
export const ErrorImg = styled.img`
  height: auto;
  width: 20vw;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    width: 50%;
  }
`;
