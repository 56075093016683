import { DefaultTheme } from 'styled-components';
import { common } from './common';

const base: DefaultTheme = {
  ...common,
  color: {
    white: 'rgba(255, 250, 250)', // #FFFAFA
    whiteOp10: 'rgba(255, 250, 250, 0.1)',
    whiteOp75: 'rgba(255, 250, 250, 0.75)',
    black: '#000000',
    primary: 'rgba(0, 159, 227)', // #009FE3
    textActive: '#4C5667',
    inputGrey: 'rgba(118, 118, 128, 0.12)',
    backgroundColor: 'rgba(237, 119, 37, 1)',
    headerBackgroundColor: 'rgba(255, 255, 255, 1)',
    contentBackgroundColor: 'rgba(20, 138, 187, 0.3)',
    gdprGrey: 'rgba(212, 215, 220)', // #d4d7dc
    gdprGrey2: 'rgba(103, 108, 116)', // #676c74
    titleColor: '#4C5667',
    transparent: 'transparent',
    divider: '#F5F5F7',
    error: '#F16C4E',
    scrollBar: 'rgba(20, 138, 187, 0.1)',
    scrollBarBackground: '#FFFFFF',
    componentBackground: '#FFFFFF',
    primaryText: '#FFFFFF',
    warning: '#f5d924',
    hoverScrollBar: '#148ABB',
    backgroundColor60Opacity: 'rgba(237, 119, 37, 0.6)',
    grey1: 'rgba(221, 223, 226)', // #dddfe2
    grey2: 'rgba(55, 58, 66)', // #373A42
    grey3: 'rgba(65, 67, 76)', // #41434C
    greyDark: 'rgb(76,86,103)', // '#4C5667'
    shadeGrey1: 'rgba(76, 92, 102)', // #4C5C66
    shadeGrey2: 'rgba( 83, 88, 104)', // #535868
    shadeGrey3: 'rgba(44, 47, 56)', // #2C2F38
    shadeGrey4: 'rgba( 144, 144, 144)', // #909090
    lightGrey: 'rgba( 238, 238, 238)', // #EEEEEE
    lightGrey2: 'rgba(245, 245, 245)', // #F5F5F5
    strokeGrey: 'rgba(237, 237, 237)', // #EDEDED
    mobilityGrey: 'rgba(39, 52, 61)', // #27343D
    mobilityGrey3: 'rgba(60, 72, 80)', // #3C4850
    mobilityBlue: 'rgba(0, 158, 224)', // #009EE0
    success: '#00e52e',
    success2: '#7FBA7A',
    toastDefault: 'rgba(201, 221, 238, 0.8)',
    pending: '#FFCE73',
    lightest: '#FAFAFB',
    lightDarkest: '#364154',
    darkest: '#192232',
    redSoft: 'rgba(241, 108, 78, 0.1)',
    redHard: 'rgba(241, 108, 78, 0.5)',
    orange: '#F16C4E',
    orangeSoft: 'rgba(255, 206, 115, 0.1)',
    green: '#7FBA7A',
    greenLight: '#BCEEB7',
    greenSoft: 'rgba(20, 138, 187, 0.1)',
    greyLight: 'rgb(245,245,247)', // '#F5F5F7'
    greyMedium: 'rgb(209, 213, 219)', // #D1D5DB
    greyMediumDark: 'rgba(178, 179, 189)', // #B2B3BD
    greyMediumDarkOp20: 'rgba(178, 179, 189, 0.2)',
    blackSoft: 'rgba(0, 0, 0, 0.07)',
    charcoal: '#333333',
    lightLush: 'rgba(253, 253, 253)', // #fdfdfd
    greenOp10: 'rgb(127,186,122, 0.1)',
    gradientGreen:
      'linear-gradient(0deg, rgba(127, 186, 122, 0.1), rgba(127, 186, 122, 0.1))',
    darkOrange: 'rgba(255, 206, 115, 1)',
  },
  type: 'light',
} as const;

export type BaseThemeType = typeof base;

export default base;
