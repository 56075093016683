import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

export const HOME_PAGE = 'https://we-conect.com/';

const Link = styled(DefaultLink).attrs<{
  fontSize?: string;
  $color?: string;
}>(props => ({
  color: props.$color ?? props.theme.color.white,
  fontSize: props.fontSize ?? '1em',
}))`
  color: ${props => props.color ?? props.theme.color.white};
  border: 0;
  margin: 0;
  height: fit-content;
  padding: 1rem 0 1rem 3rem;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    outline: none;
    transition: all 0.2s linear;
    text-decoration: none;
    color: ${({ theme, $color }) => $color ?? theme.color.redSoft};
  }
  &:active {
    text-decoration: none;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 1rem;
  }
`;

export const LinkSmall = styled.a.attrs(props => ({
  color: props.color ?? props.theme.color.white,
  href: props.href ?? HOME_PAGE,
}))`
  color: ${props => props.theme.color.white};
  font-size: 1.1rem;
  text-decoration: none;
  line-height: 2rem;
  margin: 1rem 0 0rem 0;
  font-family: Lato;
  font-weight: ${props => props.theme.fontWeight.weight400};
`;

export default Link;
