import {
  MainMenuDropdown,
  MainMenuItemRoot,
  RotateIcon,
} from '@composites/MainMenuItemDesktop/styles';
import {
  CoLocatedText,
  CompanyLogo,
  CompanyLogoContainer,
  SubMenuItems,
  WeMenuItemWrapper,
} from './styles';

import Icon from '@components/Icon';
import { adminEventsRouteFormat } from '@constants/paths';
import { PublicEventDetailsResponse } from '@hubs101/booking-api-skd-client/lib/types';
import { useSpring } from '@react-spring/web';
import { getEventDetails } from '@store/slices/eventSlice';
import { getImageSrc } from '@utils/fn';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface WeMenuItemProps {
  title?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  coLocatedEvents: PublicEventDetailsResponse[] | undefined;
}

const CompanyLogoWrapper = ({
  src,
  onClick,
  isFirstItem,
  isLastItem,
}: {
  src: string;
  onClick: () => void;
  isFirstItem: boolean;
  isLastItem: boolean;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <CompanyLogoContainer
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      onClick={onClick}
      $isFirstItem={isFirstItem}
      $isLastItem={isLastItem}
    >
      <CompanyLogo src={src} $isHovered={isHovered} />
    </CompanyLogoContainer>
  );
};

const WeMenuItem = ({
  title,
  backgroundColor,
  hoverBackgroundColor,
  coLocatedEvents,
}: WeMenuItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const props = useSpring({
    from: { transform: 'rotateZ(0deg)' },
    to: { transform: 'rotateZ(-180deg)' },
    config: { duration: 300 },
    reverse: !isHovered,
  });

  return (
    <MainMenuItemRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      <WeMenuItemWrapper
        $backgroundColor={backgroundColor}
        $isHovered={isHovered}
        $hoverBackgroundColor={hoverBackgroundColor}
      >
        <MainMenuDropdown>
          <CoLocatedText
            $color={isHovered ? theme.color.mobilityGrey : theme.color.white}
          >
            {title}
          </CoLocatedText>

          <RotateIcon style={props}>
            <Icon
              name="caret_arrow"
              color={isHovered ? theme.color.mobilityGrey : theme.color.white}
              indentLess
            />
          </RotateIcon>
        </MainMenuDropdown>
      </WeMenuItemWrapper>

      {Number(coLocatedEvents?.length) > 0 && (
        <SubMenuItems
          $isHovered={isHovered}
          $backgroundColor={theme.color.strokeGrey}
        >
          {coLocatedEvents
            ?.filter(e => e?.id !== sluggedEvent?.id)
            ?.map((sluggedEvent, index) => {
              const isLastItem =
                index ===
                coLocatedEvents?.filter(e => e?.id !== sluggedEvent?.id)
                  ?.length -
                  1;
              const isFirstItem = index === 0;
              return (
                <CompanyLogoWrapper
                  key={sluggedEvent?.id}
                  isFirstItem={isFirstItem}
                  isLastItem={isLastItem}
                  src={getImageSrc(sluggedEvent?.images.logoTransparent)}
                  onClick={() => {
                    navigate(adminEventsRouteFormat(sluggedEvent?.slug, ''));
                    setIsHovered(false);
                  }}
                />
              );
            })}
        </SubMenuItems>
      )}
    </MainMenuItemRoot>
  );
};

export default WeMenuItem;
