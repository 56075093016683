import { THEME } from '@constants/theme';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import baseTheme from '../theme/base';
import darkTheme from '../theme/dark';

interface ThemeProviderProps {
  children: React.ReactNode;
  theme: string;
}

function ThemeProvider(props: ThemeProviderProps) {
  const themeMode = props.theme === THEME.LIGHT ? baseTheme : darkTheme;

  return (
    <StyledThemeProvider theme={themeMode}>
      {props.children}
    </StyledThemeProvider>
  );
}

export default ThemeProvider;
