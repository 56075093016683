import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from './customStorage';
import { accountReducer } from './slices/accountSlice';
import { authReducer } from './slices/authSlice';
import { configReducer } from './slices/configSlice';
import { eventReducer } from './slices/eventSlice';
import { portfolioReducer } from './slices/portfolioSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuth', 'jid'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  config: configReducer,
  account: accountReducer,
  portfolio: portfolioReducer,
  event: eventReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV === 'production')
      return getDefaultMiddleware({ serializableCheck: false });
    else
      return getDefaultMiddleware({ serializableCheck: false }).concat(logger);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
