import { loaderSpin } from '@theme/animations';
import styled from 'styled-components';

export const SpinnerRoot = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
`;

export const LoadInner = styled.div`
  width: 82.5%;
  height: 85%;
  border-radius: 100%;
  position: absolute;
  top: 7.5%;
  left: 7.25%;
  animation: ${loaderSpin} (1.4s * 2.5) infinite linear;
  -o-animation: ${loaderSpin} (1.4s * 2.5) infinite linear;
  -ms-animation: ${loaderSpin} (1.4s * 2.5) infinite linear;
  -webkit-animation: ${loaderSpin} (1.4s * 2.5) infinite linear;
  -moz-animation: ${loaderSpin} (1.4s * 2.5) infinite linear;
`;

export const LoadCircle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &:nth-of-type(0) {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  &:nth-of-type(1) {
    transform: rotate(70deg);
    -o-transform: rotate(70deg);
    -ms-transform: rotate(70deg);
    -webkit-transform: rotate(70deg);
    -moz-transform: rotate(70deg);
  }
  &:nth-of-type(2) {
    transform: rotate(140deg);
    -o-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    -moz-transform: rotate(140deg);
  }
`;
export const LoadCircleInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.5rem solid rgba(0, 159, 227, 0.7);
  border-right: none;
  border-top: none;
  background-clip: padding;
  box-shadow: inset 0 0 1rem rgba(0, 159, 227, 0.15);

  &:nth-of-type(0) {
    animation: ${loaderSpin} 1.4s infinite linear;
    -o-animation: ${loaderSpin} 1.4s infinite linear;
    -ms-animation: ${loaderSpin} 1.4s infinite linear;
    -webkit-animation: ${loaderSpin} 1.4s infinite linear;
    -moz-animation: ${loaderSpin} 1.4s infinite linear;
  }
  &:nth-of-type(1) {
    animation: ${loaderSpin} 1.4s infinite linear;
    -o-animation: ${loaderSpin} 1.4s infinite linear;
    -ms-animation: ${loaderSpin} 1.4s infinite linear;
    -webkit-animation: ${loaderSpin} 1.4s infinite linear;
    -moz-animation: ${loaderSpin} 1.4s infinite linear;
  }
  &:nth-of-type(2) {
    animation: ${loaderSpin} 1.4s infinite linear;
    -o-animation: ${loaderSpin} 1.4s infinite linear;
    -ms-animation: ${loaderSpin} 1.4s infinite linear;
    -webkit-animation: ${loaderSpin} 1.4s infinite linear;
    -moz-animation: ${loaderSpin} 1.4s infinite linear;
  }
`;
