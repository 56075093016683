import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IconWrapper,
  MainMenuDropdown,
  MainMenuItemRoot,
  MainMenuItemWrapper,
  Title,
} from './styles';

import Icon from '@components/Icon';
import { adminEventsRouteFormat } from '@constants/paths';
import { setIsMenuOpen } from '@store/slices/configSlice';
import { getEventDetails } from '@store/slices/eventSlice';
import { useTheme } from 'styled-components';

interface MenuItemProps {
  title: string;
  link: string;
  subMenuItems?: any[];
  onClick?: () => void;
}

const MainMenuItemMobile = ({
  title,
  link,
  subMenuItems = [],
  onClick,
}: MenuItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean | null>(null);
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const dispatch = useDispatch();

  const isSelected = useMemo(
    () => `/${pathname.split('/')?.[3]}` === link,
    [link, pathname]
  );

  return (
    <MainMenuItemRoot
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      <MainMenuItemWrapper
        $isSelected={isSelected}
        onClick={() => {
          if (subMenuItems?.length < 1) {
            navigate(adminEventsRouteFormat(sluggedEvent?.slug, link));
          }
          onClick?.();
          if (Number(subMenuItems?.length) === 0) {
            dispatch(setIsMenuOpen(false));
          }
        }}
        $isMultiMenu={Number(subMenuItems?.length) > 0}
        $isHovered={isHovered}
        $backgroundColor={sluggedEvent?.design?.secondaryThemeColor}
      >
        {subMenuItems?.length === 0 ? (
          <>
            <Title $textColor={sluggedEvent?.design?.secondaryThemeColor}>
              {title}
            </Title>
          </>
        ) : (
          <MainMenuDropdown>
            <Title $textColor={sluggedEvent?.design?.secondaryThemeColor}>
              {title}
            </Title>
            <IconWrapper>
              <Icon
                name="caret_arrow_right"
                color={theme.color.white}
                $hoverColor={sluggedEvent?.design.themeColor}
                indentLess
              />
            </IconWrapper>
          </MainMenuDropdown>
        )}
      </MainMenuItemWrapper>
    </MainMenuItemRoot>
  );
};

export default MainMenuItemMobile;
