import { ACCOUNT_ID, usePublicPortfolioByAccount } from '@utils/apiHooks';
import {
  Column,
  MenuRight,
  SocialFooterRoot,
  SocialMenu,
  SocialWrapper,
} from './styles';
import Link, { LinkSmall } from '@components/Link';
import {
  formatWebsiteLink,
  getFullFacebookHttpLink,
  getFullInstagramHttpLink,
  getFullLinkedInHttpLink,
  getFullTwitterHttpLink,
  getFullXingHttpLink,
} from '@utils/fn';

import { FooterRoot } from '@composites/FooterMenu/styles';
import Icon from '@components/Icon';
import Logo from '@components/Logo';
import { getEventDetails } from '@store/slices/eventSlice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useViewAnalyzer } from '@utils/hooks';
import weLogo from '@assets/images/we-conect-logo.svg';
import weLogoGlobal from '@assets/images/weconect-global-white.png';

function FooterSocial() {
  const { t } = useTranslation();
  const view = useViewAnalyzer();
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const logo = useMemo(
    () => (view === 'event' ? weLogo : weLogoGlobal),
    [view]
  );
  const { data: portfolios } = usePublicPortfolioByAccount({
    accountId: ACCOUNT_ID,
  });

  const contact = useMemo(
    () => portfolios?.data?.[0]?.account?.contact,
    [portfolios?.data]
  );

  const color = useMemo(
    () => sluggedEvent?.design?.themeColor,
    [sluggedEvent?.design?.themeColor]
  );

  const socialMediaIcons = useMemo(
    () => [
      {
        name: 'social_facebook',
        url: getFullFacebookHttpLink(contact?.facebook),
      },
      {
        name: 'social_twitter',
        url: getFullTwitterHttpLink(contact?.twitter),
      },
      {
        name: 'social_instagram',
        url: getFullInstagramHttpLink(contact?.instagram),
      },
      {
        name: 'social_linkedin',
        url: getFullLinkedInHttpLink(contact?.linkedin),
      },
      { name: 'social_xing', url: getFullXingHttpLink(contact?.xing) },
      {
        name: 'social_youtube',
        url: formatWebsiteLink(contact?.youtube),
      },
    ],
    [
      contact?.facebook,
      contact?.instagram,
      contact?.linkedin,
      contact?.twitter,
      contact?.xing,
      contact?.youtube,
    ]
  );
  return (
    <SocialFooterRoot>
      <FooterRoot>
        <MenuRight>
          <Link
            to="https://we-conect.com/en/imprint-legal-notice/"
            $color={color}
          >
            {t('imprint')}
          </Link>
          <Link
            to="https://we-conect.com/en/terms-and-conditions/"
            $color={color}
          >
            {t('terms-&-conditions')}
          </Link>
          <Link to="https://we-conect.com/data-privacy" $color={color}>
            {t('privacy-policy')}
          </Link>
        </MenuRight>
        <SocialMenu>
          <Column>
            <Logo src={logo} width="17.6rem" $clickable />
            {view !== 'event' && (
              <LinkSmall>© we.CONECT Global Leaders GmbH</LinkSmall>
            )}
          </Column>
          <SocialWrapper>
            {socialMediaIcons?.map(({ name, url }) => (
              <Icon
                key={name}
                testId={name}
                name={name}
                color="white"
                $clickable
                $hoverEffect
                $hoverColor={color}
                onClick={() => {
                  window.open(url, '_blank');
                }}
              />
            ))}
          </SocialWrapper>
        </SocialMenu>
      </FooterRoot>
    </SocialFooterRoot>
  );
}

export default FooterSocial;
