import styled, { DefaultTheme } from 'styled-components';

import { Link } from 'react-router-dom';

export interface TypographyProps {
  theme: DefaultTheme;
}
export const H1 = styled.h1`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size55};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight900};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height63};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const H2 = styled.h2`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size50};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight900};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height70};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const H3 = styled.h3`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size35};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height125};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const H4 = styled.h4`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size28};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height39};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const H5 = styled.h5`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size25};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height125};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const H6 = styled.h6`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size20};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height125};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.titleColor};
  background-color: transparent;
`;

export const P = styled.p`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size20};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height30};
  color: ${(props: TypographyProps) => props.theme.color.primaryText};
`;

export const PBold = styled.p`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size22};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height33};
  color: ${(props: TypographyProps) => props.theme.color.primaryText};
`;

export const PSmall = styled.p<{
  color?: string;
}>`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size16};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height150};
  color: ${props => props.color ?? props.theme.color.primaryText};
`;

export const SpanSmall = styled.span<{
  color?: string;
}>`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size125};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height150};
  color: ${props => props.color ?? props.theme.color.primaryText};
`;

export const PSmallBold = styled.p`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size16};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height150};
  color: ${(props: TypographyProps) => props.theme.color.primaryText};
`;

export const Small = styled.small`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size11};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height150};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.primaryText};
`;

export const SmallBold = styled.small`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size11};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight600};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height150};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  color: ${(props: TypographyProps) => props.theme.color.primaryText};
`;

export const LinkHeader = styled(Link)`
  font-family: ${(props: TypographyProps) => props.theme.font.header};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size14};
  color: ${(props: TypographyProps) => props.theme.color.primary};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const LinkExternal = styled.a`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size14};
  color: ${(props: TypographyProps) => props.theme.color.primary};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const LinkExternalSmall = styled.a`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size11};
  color: ${(props: TypographyProps) => props.theme.color.primary};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight700};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Caption = styled.p`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size14};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height125};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  text-transform: uppercase;
`;

export const CaptionSmall = styled.p`
  font-family: ${(props: TypographyProps) => props.theme.font.body};
  font-size: ${(props: TypographyProps) => props.theme.fontSize.size11};
  line-height: ${(props: TypographyProps) => props.theme.lineHeight.height125};
  font-weight: ${(props: TypographyProps) => props.theme.fontWeight.weight400};
  letter-spacing: ${(props: TypographyProps) => props.theme.letterSpacing.zero};
  text-transform: uppercase;
`;

LinkExternal.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

LinkExternalSmall.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
