import { CenterItem, Column } from '@components/LinearLayout';

import Spinner from '@components/Spinner';
import styled from 'styled-components';

const RootLoadingFallback = styled(CenterItem)`
  height: 80vh;
`;

export const SpinnerWrapper = styled(Column)`
  align-items: center;
`;

const LoadingFallBack = () => {
  return (
    <RootLoadingFallback>
      <SpinnerWrapper $gap="2rem">
        <Spinner />
      </SpinnerWrapper>
    </RootLoadingFallback>
  );
};

export default LoadingFallBack;
