import { scaleInType3, scaleOutType3 } from '@theme/animations';
import styled, { css } from 'styled-components';

import { Column } from '@components/LinearLayout';
import { PSmall } from '@components/Typography';

export const CoLocatedText = styled(PSmall)<{ $color?: string }>`
  color: ${({ $color }) => $color};
  font-weight: ${({ theme }) => theme.fontWeight.weight500};
  font-size: ${({ theme }) => theme.fontSize.size16};
  white-space: nowrap;
`;

export const SubMenuItems = styled(Column)<{
  $isHovered: boolean;
  $backgroundColor: string;
}>`
  position: absolute;
  top: 100%;
  left: 0;
  position: absolute;
  width: inherit;
  align-items: center;
  padding: 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.radius20};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.radius20};
  max-height: 0;
  z-index: 1;
  list-style: none;
  overflow: hidden;
  transition: max-height 300ms linear;
  gap: 0;
  ${({ $isHovered }) =>
    $isHovered
      ? css`
          max-height: 29rem;
          pointer-events: auto;
        `
      : css`
          max-height: 0;
          pointer-events: none;
        `}
`;

export const WeMenuItemWrapper = styled.div<{
  $backgroundColor: string;
  $isHovered: boolean;
  $hoverBackgroundColor: string;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  transition: background-color 300ms ease-in-out;
  white-space: nowrap;
  ${({ $isHovered, $hoverBackgroundColor, $backgroundColor }) =>
    $isHovered
      ? css`
          background-color: ${$hoverBackgroundColor};
        `
      : css`
          background-color: ${$backgroundColor};
        `}

  &:hover {
    cursor: pointer;
  }
`;

export const CompanyLogo = styled.img<{ $isHovered: boolean }>`
  height: auto;
  width: 100%;
  object-fit: cover;
  padding: 0 4rem;
  cursor: pointer;

  ${({ $isHovered }) =>
    $isHovered
      ? css`
          animation: ${scaleOutType3} 400ms ease-in forwards;
        `
      : css`
          animation: ${scaleInType3} 400ms ease-in forwards;
        `}
`;

export const CompanyLogoContainer = styled.div<{
  $isFirstItem: boolean;
  $isLastItem: boolean;
}>`
  padding: 1rem 0;
  ${({ $isFirstItem }) => $isFirstItem && `padding-top: 2rem;`};
  ${({ $isLastItem }) => $isLastItem && `padding-bottom: 2rem;`}
`;
