import { counterDate, eventTimeFormat } from '@utils/time';
import {
  AddressText,
  DateText,
  LeftSideContent,
  TopHeaderBody,
} from './styles';

import { getLanguage } from '@store/slices/configSlice';
import { getEventDetails } from '@store/slices/eventSlice';
import { useTabletWidth } from '@utils/hooks';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const HeaderTopComponent = () => {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const sluggedEvent = useSelector(getEventDetails)?.[0];
  const isTablet = useTabletWidth();

  const startDateCounter = useMemo(() => {
    return counterDate(sluggedEvent?.start_date);
  }, [sluggedEvent?.start_date]);

  if (isTablet) return null;
  return (
    <TopHeaderBody color={sluggedEvent?.design?.secondaryThemeColor} $isLeft>
      <LeftSideContent $gap="2.4rem">
        <AddressText>
          {`${eventTimeFormat(
            sluggedEvent?.start_date ?? '',
            sluggedEvent?.end_date ?? '',
            language
          )}
             ${sluggedEvent?.location?.name ? ` | ${sluggedEvent?.location?.name}` : ''}`}
        </AddressText>
        <DateText>
          {startDateCounter ? ` →  ${t('still')} ${startDateCounter}` : ''}
        </DateText>
      </LeftSideContent>
    </TopHeaderBody>
  );
};

export default HeaderTopComponent;
