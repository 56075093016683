import styled from 'styled-components';

const Logo = styled.img<{
  backgroundColor?: string;
  width?: string;
  height?: string;
  $clickable?: boolean;
  src?: string;
}>`
  background-color: ${props =>
    props.backgroundColor ?? props.theme.color.transparent};
  width: ${props => props.width ?? 'wrap-content'};
  height: ${props => props.height ?? 'auto'};
  object-fit: contain;
  cursor: ${props => (props.$clickable ? `pointer` : 'auto')};
`;
export default Logo;
