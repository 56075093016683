import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const cubicAscendant = keyframes`
  0%{
    transform: translate(0, 20rem);
    opacity:0;
  }
  100% {
    transform: translate(0, 0);
    opacity:1;
     
  }
`;

export const cubicLeft = keyframes`
  0%{
    transform: translate(-20rem, 0);
    opacity:0;
  }
  100% {
    transform: translate(0, 0);
    opacity:1;
    curve:0.57, 0.61, 0, 0.97;
  }
`;

export const cubicRight = keyframes`
  0%{
    transform: translate(20rem, 0);
    opacity:0;
  }
  100% {
    transform: translate(0, 0);
    opacity:1;
    curve:0.57, 0.61, 0, 0.97;
  }
`;

export const scrollDown = keyframes`
  0% {
    bottom: 0;
    top: 0;
  }
  100% {
    bottom: 2rem; 
    top: 3rem;
  }
`;

export const slideIn = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: auto;
  }
`;

export const cubicBottom = keyframes`
  0%{
    transform: translate(0,-40rem);
    opacity:0;
  }
  100% {
    transform: translate(0, 0);
    opacity:1;
  }
`;

export const cubicTop = keyframes`
  0%{
    transform: translate(0,40rem);
    opacity:0;
  }
  100% {
    transform: translate(0, 0);
    opacity:1;
  }
`;

export const scaleIn = keyframes`
  0%{
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

export const scaleOut = keyframes`
  0%{
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const growDown = keyframes`
0% {
  transform: scaleY(0);
  opacity:0
}
80% {
  transform: scaleY(1.1);
  opacity:0.8
}
100% {
  transform: scaleY(1);
  opacity:1
}
`;

export const growUp = keyframes`
0% {
  transform: scaleY(1);
  opacity:1
}
20% {
  transform: scaleY(1.1);
  opacity:0.2
}
100% {
  transform: scaleY(0);
  opacity:0
}
`;

export const rotateTopZ = keyframes`
0% {
  transform: rotateZ(-90deg)
}
80% {
  transform: rotateZ(100deg)
}
100% {
  transform: rotateZ(90deg)
}
`;

export const rotateBottomZ = keyframes`
0% {
  transform: rotateZ(90deg)
}
80% {
  transform: rotateZ(-80deg)
}
100% {
  transform: rotateZ(-90deg)
}
`;

export const moveRight = keyframes`
0% {
  transform:translateX(0);
}
100% {
  transform:translateX(40rem);
}
`;

export const moveLeft = keyframes`
0% {
  transform:translateX(-40rem);
}
100% {
  transform:translateX(0);
}
`;

export const moveBottom = keyframes`
0% {
  transform:translateY(-20rem);
}

100% {
  transform:translateY(0);
}
`;

export const rotateZ = keyframes`
0% {
  transform: rotateZ(180deg)
}
100% {
  transform: rotateZ(0deg)
}
`;

export const reverseRotateZ = keyframes`
0% {
  transform: rotateZ(0deg)
}
100% {
  transform: rotateZ(180deg)
}
`;

export const dividerAscendant = keyframes`
0%{
  transform: translate(0, 0.5rem);
  opacity:0;
  scale:1.3
}
100% {
  transform: translate(0, 0);
  opacity:1;
  scale:1
}
`;

export const dividerDescendant = keyframes`
0%{
  transform: translate(0, 0);
  opacity:1;
  scale:1
}
100% {
  transform: translate(0, 0.5rem);
  opacity:0;
  scale:1.3
}
`;

export const scaleInType2 = keyframes`
  0%{
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
`;
export const scaleOutType2 = keyframes`
  0%{
    transform: scale(1);
  }
  100% {
    transform: scale(0.98);
  }
`;

export const loaderSpin = keyframes`
 0%{
   	transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
`;

export const scaleInType3 = keyframes`
  0%{
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;
export const scaleOutType3 = keyframes`
  0%{
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;
