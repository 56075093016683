import {
  CenterItem,
  Column,
  Row,
  RowAlignedItems,
} from '@components/LinearLayout';

import { NAV_SMALL_HEIGHT } from '@utils/constants';
import { styled } from 'styled-components';

export const NavBarRoot = styled(Column)`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
`;

export const MenuOpenBody = styled(Column)`
  position: absolute;
  width: 100%;
  background-color: ${props => props.theme.color.backgroundColor};
  top: ${NAV_SMALL_HEIGHT};
`;

export const LogoWrapper = styled(CenterItem)`
  cursor: pointer;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 0 0.5rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
`;

export const HeaderColor = styled.div`
  height: 2rem;
  background-color: ${props => props.theme.color.backgroundColor};
  width: 100%;
`;

export const MenuItems = styled(RowAlignedItems)`
  height: 100%;
`;
export const RightSideContent = styled(RowAlignedItems)`
  padding: 0 2rem;
  gap: 2rem;
`;

export const MenuBodyDesktop = styled(Row)`
  width: 100%;
  height: ${NAV_SMALL_HEIGHT};
  align-items: center;
`;

export const MenuBar = styled.div`
  margin-left: auto;
  padding: 0 0.5rem;
`;

export const CentralMenu = styled(CenterItem)`
  width: 100%;
  height: 100%;
`;

export const MenuOpenFooter = styled.div`
  border-top: 0.05rem solid ${props => props.theme.color.black};
  height: 2rem;
  background-color: ${props => props.theme.color.white};
`;

export const EventMenuRoot = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  width: 100%;
  display: flex;
`;

export const EventTopMenuBody = styled(Row)`
  width: min(100%, 192rem);
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0rem 10rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    padding: 0 3rem;
  }
`;
