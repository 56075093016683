import { Column, Row } from '@components/LinearLayout';
import { H3, H4, P, PBold, TypographyProps } from '@components/Typography';

import { EVENT_HOME_WIDTH } from '@utils/constants';
import { Link } from 'react-router-dom';
import Logo from '@components/Logo';
import { styled } from 'styled-components';

export const MenuFooterRoot = styled(Row)<{ $backgroundColor?: string }>`
  background-color: ${props =>
    props.$backgroundColor ? props.$backgroundColor : props.theme.color.black};
  justify-content: center;
`;

export const Subtitle = styled(H3)<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.color.white)};
  line-height: ${(props: TypographyProps) => props.theme.fontSize.size24};
  margin: 2rem 0 1rem 0;
`;

export const Description = styled(H4)<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.color.white)};
  line-height: ${(props: TypographyProps) => props.theme.fontSize.size24};
`;

export const Column33 = styled(Column)`
  width: 33.33%;
  padding: 3rem;
  gap: 0.5rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 3rem 3rem 3rem;
  }
`;

export const Column50 = styled(Column)`
  width: 50%;
  padding: 3rem;
  gap: 0.5rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 3rem 3rem 3rem;
  }
`;

export const CoLocatedRo = styled(Row)`
  margin: 0 0 0 -1rem;
`;

export const CoLocatedLogo = styled(Logo)`
  max-width: 50%;
  padding: 2rem;
  border: 1rem solid ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};
`;

export const RowOne = styled(Column33)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 3rem 3rem 3rem;
  }
`;

export const RowTwo = styled(Row)`
  flex-direction: row;
  width: 66.66%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(24.5%, 1fr) minmax(17.5%, 1fr) minmax(17.5%, 1fr)
    minmax(24.5%, 1fr);
  grid-gap: 5.33%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1280}) {
    grid-template-columns:
      minmax(24%, 1fr) minmax(21.5%, 1fr) minmax(21.5%, 1fr)
      minmax(24%, 1fr);
    grid-gap: 3%;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    grid-template-columns:
      minmax(27%, 1fr) minmax(21%, 1fr) minmax(21%, 1fr)
      minmax(27%, 1fr);
    grid-gap: 1.33%;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    flex-direction: column;
  }
`;

export const FooterRoot = styled.div`
  width: min(${EVENT_HOME_WIDTH}, 100%);
  margin: auto;
  padding: 2rem 3rem;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 2rem 1rem;
  }
`;

export const RowCol = styled(Row)`
  width: 100%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ZoomInLink = styled(Link)<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.color.white)};
  &:hover {
    transition: all 0.2s linear;
    font-weight: ${props => props.theme.fontWeight.weight700};
    outline: none;
    text-decoration: none;
  }
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

export const SaveTheDate = styled(Link)<{ color?: string }>`
  max-width: 23rem;
  justify-content: center;
  display: flex;
  color: ${props => (props.color ? props.color : props.theme.color.white)};
  background-color: ${props => props.theme.color.primary};
  font-weight: ${props => props.theme.fontWeight.weight700};
  border: 0.1rem solid ${props => props.theme.color.primary};
  outline: none;
  text-decoration: none;
  margin: 3rem 0 0 0;
  padding: 1.6rem 1.8rem;
  &:hover {
    transition: all 0.2s linear;
    background-color: ${props => props.theme.color.black};
    border: 0.1rem solid ${props => props.theme.color.white};
    outline: none;
    text-decoration: none;
  }
`;

export const EventDetailsSection = styled(Column)`
  gap: 2rem;
  width: 100%;
  padding: 6rem 2rem 6rem 0;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 6rem 0;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    padding: 1rem 0;
    text-align: center;
    display: flex;
    align-items: center;
  }
`;
export const DesktopOnlyColumn = styled(Column)`
  gap: 1rem;
  width: 100%;
  padding: 4rem 0;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: none;
  }
`;

export const EventDetailsDescription = styled(P)`
  color: ${({ theme }) => theme.color.mobilityGrey};
  font-size: ${({ theme }) => theme.fontSize.size18};
  line-height: ${({ theme }) => theme.lineHeight.height150};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width1080}) {
    padding: 0 1rem;
  }
`;

export const TextLink = styled.a<{ $color?: string }>`
  color: ${({ theme, $color }) => $color ?? theme.color.mobilityGrey};
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
`;

export const DetailTitle = styled(PBold)<{ $color?: string }>`
  font-size: ${({ theme }) => theme.fontSize.size20};
  font-weight: ${props => props.theme.fontWeight.weight900};
  line-height: ${props => props.theme.lineHeight.height150};
  color: ${({ theme }) => theme.color.mobilityGrey};
  transition: color 300ms linear;
  &:hover {
    color: ${({ $color }) => $color};
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    display: flex;
    justify-content: center;
  }
`;

export const ContactUs = styled.a`
  text-decoration: none;
`;

export const LinkElement = styled(Row)<{ $color: string }>`
  gap: 0.3rem;
  color: ${({ theme }) => theme.color.mobilityGrey};
  font-size: ${({ theme }) => theme.fontSize.size18};
  font-weight: ${props => props.theme.fontWeight.weight400};
  line-height: ${props => props.theme.lineHeight.height150};
  transition: all 0.2s linear;
  word-break: break-all;
  &:hover {
    gap: 0.1rem;
    color: ${({ $color }) => $color};
  }
`;

export const LinkElementRoot = styled(Link)`
  &:hover {
    outline: none;
    text-decoration: none;
  }
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

export const CompanyLogo = styled.img`
  height: auto;
  width: 90%;
  object-fit: cover;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width768}) {
    max-width: 20rem;
  }
`;
